/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    div: "div"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "Kredītvēsture ir personas finansiālo aktivitāšu apkopojums, kurā parādās informācija gan par agrākām, gan esošām kredītsaistībām, tai skaitā gan izpildītām, gan aktīvām."
    }), "\n", _jsx(_components.p, {
      children: "Kredītvēsturē redzams ne tikai aizdevumu noformēšanas laiks, bet arī līgumu summas, aizdevēja nosaukums un cita veida dati. Pieejama arī informācija par maksāšanas disciplīnu – vai saistības izpildītas laicīgi, vai arī bijuši kavējumi. Hipotekārie kredīti, patēriņa kredīti, auto līzingi, studiju kredīti, kredītkartes vai kredītlīnijas, neapmaksāti apsaimniekošanas vai īres rēķini, pat neapmaksāti sodi par auto novietošanu neatļautā vietā – viss minētais nosaka, vai kredītvēsture būs pozitīva vai negatīva."
    }), "\n", _jsx(_components.h2, {
      children: "Personiskā informācija"
    }), "\n", _jsx(_components.p, {
      children: "Šī informācija tiek izmantota, lai apliecinātu Jūsu identitāti. Šo informāciju izmanto KIB, aizdevēji un Bankas."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/b5533d8150d16f5efb8494f37f4486b5/e548f/article3_lv_image1.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 14.18918918918919%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAZ0lEQVQI11VOWwrAIAzb/c/qh7Na32akIHSBUFvy8Mk5o9aKtZbNEAJSEsw5Mcaw2XuHiBj5PudAhL4GgjtJPDG+KEXtQHNtDXtveHBnMQNZwp3lV/cLVFX7nT960SU1JEO81nsY+AGg/uwzYgEntQAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Personiskā informācija piemērs\"\n        title=\"\"\n        src=\"/static/b5533d8150d16f5efb8494f37f4486b5/fcda8/article3_lv_image1.png\"\n        srcset=\"/static/b5533d8150d16f5efb8494f37f4486b5/12f09/article3_lv_image1.png 148w,\n/static/b5533d8150d16f5efb8494f37f4486b5/e4a3f/article3_lv_image1.png 295w,\n/static/b5533d8150d16f5efb8494f37f4486b5/fcda8/article3_lv_image1.png 590w,\n/static/b5533d8150d16f5efb8494f37f4486b5/efc66/article3_lv_image1.png 885w,\n/static/b5533d8150d16f5efb8494f37f4486b5/e548f/article3_lv_image1.png 975w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Privātpersonas kredītreitings (CIP)"
    }), "\n", _jsx(_components.p, {
      children: "Privātpersonas Kredītreitings ir visas Kredītinformācijas Biroja datu bāzē par konkrēto personu pieejamās informācijas analīzes rezultāts. Privātpersonas Kredītreitings ir paredzēts visas pieejamās informācijas apkopošanai vienā rādītājā, kas tiek attēlots gan kvalitatīvi, gan kvantitatīvi. Kredītrisks ir kvantitatīvs rādītājs, kas svārstās no 250 līdz 900, kur lielāks skaitlis parāda zemāku risku."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/387a7406c57cc992b9ba34c0bc1dcf48/b97f6/article3_lv_image2.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 18.243243243243242%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAp0lEQVQY011PWw6DMAzrobfjcY+xvzEYlA8YLX2mradWg1WzZEWJldhhfhOgfQcpBS8k/PoGyR0xxsKUEjJyPfqYUqn/zDrTjw5qeEGNE+w8wwwjLJ8RQyyHYgiw1iKEcC7VJCJ470+d8esFU3vHZi1qZFEIAa0UlmWFc/6cZ3DO0fdPdF2HW3uH1qbMmWgakJRI3zS1u3OuONcvH2/n1MaYohP90n8AC/g3O+SkKx8AAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Privātpersonas kredītreitings piemērs\"\n        title=\"\"\n        src=\"/static/387a7406c57cc992b9ba34c0bc1dcf48/fcda8/article3_lv_image2.png\"\n        srcset=\"/static/387a7406c57cc992b9ba34c0bc1dcf48/12f09/article3_lv_image2.png 148w,\n/static/387a7406c57cc992b9ba34c0bc1dcf48/e4a3f/article3_lv_image2.png 295w,\n/static/387a7406c57cc992b9ba34c0bc1dcf48/fcda8/article3_lv_image2.png 590w,\n/static/387a7406c57cc992b9ba34c0bc1dcf48/efc66/article3_lv_image2.png 885w,\n/static/387a7406c57cc992b9ba34c0bc1dcf48/b97f6/article3_lv_image2.png 958w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Uzņēmuma kredītreitings (CIR)"
    }), "\n", _jsx(_components.p, {
      children: "Uzņēmuma Kredītreitings ir visas Kredītinformācijas Biroja datu bāzē par konkrēto uzņēmumu pieejamās informācijas analīzes rezultāts. Uzņēmuma Kredītreitings ir paredzēts visas pieejamās informācijas apkopošanai vienā rādītājā, kas tiek attēlots gan kvalitatīvi, gan kvantitatīvi. Kredītrisks ir kvantitatīvs rādītājs, kas svārstās no 0 līdz 130, kur lielāks skaitlis parāda zemāku risku."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/d4762b801932c923ac24d360c3944645/e548f/article3_lv_image3.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 14.864864864864865%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAhklEQVQI11WOywrCQAxF+/+f4S8IbgTXgiuXXYubKhPszNDM8xQHK9PAJSQ33JMBpyCeKEKcJqIx1Fqbttrmftd7pZR/H27PO6fxgn0bZHqhzrXDnDO6LFhrd4Hee5xzfOYZEUE17KDD+XHlMB6hQMyJ8vtCVZtSSrvALyCEQEyJEGID9/4K08rpfMkezC8AAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Uzņēmuma kredītreitings piemērs\"\n        title=\"\"\n        src=\"/static/d4762b801932c923ac24d360c3944645/fcda8/article3_lv_image3.png\"\n        srcset=\"/static/d4762b801932c923ac24d360c3944645/12f09/article3_lv_image3.png 148w,\n/static/d4762b801932c923ac24d360c3944645/e4a3f/article3_lv_image3.png 295w,\n/static/d4762b801932c923ac24d360c3944645/fcda8/article3_lv_image3.png 590w,\n/static/d4762b801932c923ac24d360c3944645/efc66/article3_lv_image3.png 885w,\n/static/d4762b801932c923ac24d360c3944645/e548f/article3_lv_image3.png 975w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Pieprasījumi"
    }), "\n", _jsx(_components.p, {
      children: "Izziņa sniedz arī informāciju par finanšu institūciju veikto pieprasījumu skaitu noteiktā laika periodā un abonentu (dažādu finanšu institūciju) skaitu, kas par šo personu veica pieprasījumu noteiktā laika periodā. Šī informācija ir arī viens no faktoriem, veidojot gala Vērtējumu. Pieprasījumu indikators var būt vienā no trim krāsām: zaļā, oranžā un sarkanā, Ja indikators ir sarkanā krāsā tas var norādīt uz to, ka bija pārāk daudz nesen veiktu pieprasījumu, kas ir kā brīdinājums par iespējamu krāpniecisku rīcību."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/ee38fb38f071a13883377f7e7768f128/e548f/article3_lv_image4.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 17.567567567567565%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAq0lEQVQY032O22oCQRBE96PNXwR/ykhA3+JzIKAkuNObHXVn1rn0HBlRUEksKBpO013V5M4S+57YGqIIQQRkB9YRjCH1llT30hGNoClRSkFV0TpvXHmz+flkkJ6dMdi2Je0dq/WSxdeMwXRnduh+2RvBbltiCFSVy4NHN2/fc0qAg3dnV71+TJm8v1ASDN4zHo+4ccR5T8qZZ2q06B2oKVkzWdOfB/81uzY8AamVNbrcYHMwAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Pieprasījumi piemērs\"\n        title=\"\"\n        src=\"/static/ee38fb38f071a13883377f7e7768f128/fcda8/article3_lv_image4.png\"\n        srcset=\"/static/ee38fb38f071a13883377f7e7768f128/12f09/article3_lv_image4.png 148w,\n/static/ee38fb38f071a13883377f7e7768f128/e4a3f/article3_lv_image4.png 295w,\n/static/ee38fb38f071a13883377f7e7768f128/fcda8/article3_lv_image4.png 590w,\n/static/ee38fb38f071a13883377f7e7768f128/efc66/article3_lv_image4.png 885w,\n/static/ee38fb38f071a13883377f7e7768f128/e548f/article3_lv_image4.png 975w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Līgumi"
    }), "\n", _jsx(_components.p, {
      children: "Sadaļā Līgumi ir apkopots Maksājumu profils, kas ir īss kopsavilkums par visu ar personu saistīto aizdevumu maksājumu statusu. Šeit var redzēt kopsavilkumu ar maksājumu kavējumiem, dažādu kreditoru skaitu un atlikušo nenomaksāto saistību kopsummu. Pēc rezultāta novērtēšanas Izziņas pieprasītājs var apskatīt arī sīkāku informāciju par katru līgumu. Maksājumu profils galvenokārt ir paredzēts negatīvu maksājumu informācijas atspoguļošanai, lai Izziņas pieprasītājs var ātri pieņemt lēmumu vai nepieciešama tālāka katra līguma apskate."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/0f8c7138cdf46ceedb82d40fdc2a3171/121b3/article3_lv_image5.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 27.027027027027025%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAtElEQVQY021Qiw6DIAzk//2w/YIuY5lzSOaDoXHyULkFFowsu6RpKdxxLTHyBSsFPJxz2LYthK+PEe//vYm1z+Q9DBCMQQiBaZoA9yWu64plWfZsjIG1NvnE93+FiTidcMky3CiF0goRXdfhSinuZYnm2YSzlDJx+6gqKKUS98SOIzTnu1Ak1Iwhz3OciwJt0wYx7zLCu+GcY57nVNAdhI778gK85oHU931YiR85Qmud9OLIHwWEhI5e5E+VAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Līgumi piemērs\"\n        title=\"\"\n        src=\"/static/0f8c7138cdf46ceedb82d40fdc2a3171/fcda8/article3_lv_image5.png\"\n        srcset=\"/static/0f8c7138cdf46ceedb82d40fdc2a3171/12f09/article3_lv_image5.png 148w,\n/static/0f8c7138cdf46ceedb82d40fdc2a3171/e4a3f/article3_lv_image5.png 295w,\n/static/0f8c7138cdf46ceedb82d40fdc2a3171/fcda8/article3_lv_image5.png 590w,\n/static/0f8c7138cdf46ceedb82d40fdc2a3171/efc66/article3_lv_image5.png 885w,\n/static/0f8c7138cdf46ceedb82d40fdc2a3171/121b3/article3_lv_image5.png 1070w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Kredītu nodrošinājumi"
    }), "\n", _jsx(_components.p, {
      children: "Kredītu nodrošinājums ir kopsavilkums par personas aktuāliem aizdevumu nodrošinājumiem."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/0cef87a67245f7fc467ff7dc837f4595/e548f/article3_lv_image6.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 14.864864864864865%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAUElEQVQI153OSw6AIAwAUe5/T42C/BZgbG3HeAUWbzuZILXxpAstBYkJF8EBM1sSnjkZrZOPk7jtlFxQfXH3JUFUmfdN651cK2MMzNZi/+EHwITsCrOd+fcAAAAASUVORK5CYII='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Kredītu nodrošinājumi piemērs\"\n        title=\"\"\n        src=\"/static/0cef87a67245f7fc467ff7dc837f4595/fcda8/article3_lv_image6.png\"\n        srcset=\"/static/0cef87a67245f7fc467ff7dc837f4595/12f09/article3_lv_image6.png 148w,\n/static/0cef87a67245f7fc467ff7dc837f4595/e4a3f/article3_lv_image6.png 295w,\n/static/0cef87a67245f7fc467ff7dc837f4595/fcda8/article3_lv_image6.png 590w,\n/static/0cef87a67245f7fc467ff7dc837f4595/efc66/article3_lv_image6.png 885w,\n/static/0cef87a67245f7fc467ff7dc837f4595/e548f/article3_lv_image6.png 975w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Kavētie rēķini"
    }), "\n", _jsx(_components.p, {
      children: "Šī sadaļa ietver informāciju par kavētiem rēķiniem par kuriem saņemta informācija no Datu devējiem."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/9dc66511c9823775406e94ec7084eabc/e548f/article3_lv_image7.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 27.7027027027027%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5ElEQVQY032Q626DMAyF8/5v2EmdWDs2GkIoIeQezmSnVFt/zJJ17M+WnViE4RtR3lC8A9m+703R9DDih//HhJtnBGMQnUdMCTlnxBiRUkIIAXa1z7x5q7+yEBoTgxzhfIAxK+73BdZuuHxcME0aSk04n9+xLAZ9/8WMeq7XT9ZhuEEphXW16PseWs8QXdfBGMMNVCyl8KZSK3MpJXJujF7vnIPWmuuk27bBe8/Laq0Qb6cTAx6U4p+7EKMv/b4TDaQllI3jCGst3/voEzSVAtJKQ2p9+iujPPPixHF63Lw+atTzA9mx0u68mk89AAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Kavētie rēķini piemērs\"\n        title=\"\"\n        src=\"/static/9dc66511c9823775406e94ec7084eabc/fcda8/article3_lv_image7.png\"\n        srcset=\"/static/9dc66511c9823775406e94ec7084eabc/12f09/article3_lv_image7.png 148w,\n/static/9dc66511c9823775406e94ec7084eabc/e4a3f/article3_lv_image7.png 295w,\n/static/9dc66511c9823775406e94ec7084eabc/fcda8/article3_lv_image7.png 590w,\n/static/9dc66511c9823775406e94ec7084eabc/efc66/article3_lv_image7.png 885w,\n/static/9dc66511c9823775406e94ec7084eabc/e548f/article3_lv_image7.png 975w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Apgrūtinājumi, Dalības un Līdzdalības uzņēmumos"
    }), "\n", _jsx(_components.p, {
      children: "Sadaļās “Apgrūtinājumi” un “Dalības un Līdzdalības uzņēmumos” var redzēt informāciju vai persona ir saistīta ar kādu citu personu kā līdzaizņēmējs, garantijas devējs vai ir kādas Līdzdalības uzņēmumos utml."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/d427ed385906ee7f688c53ef68fc9ca5/e548f/article3_lv_image8.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 37.83783783783784%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA0ElEQVQoz5VR0W7DIAzk/79w20PXvWxNQtcSoICNuclMjVItk1JLJ/xgH3c+kz+/UMYJTRpaaxCR/t5L+2dg8uyRQ0ApZSHS3loL7z1qlX+Xa60dD4RUK3wIcG6GyC+hEr28vuF4/EAIcVG6Vq1FRCDmBycGG4MxRBwO75i9/0O2rrvC9b7R0S3whp1dNyzDCL5cUU4n0GR7QGWyaETLzxrUXpjoHNz5G5dxxHkYEK8OnBLy7dZv6Ve2dylMOXd7Go4euGp6IkgpgZlBxE9Z/gEhyHX8f+i6ZQAAAABJRU5ErkJggg=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Apgrūtinājumi, Dalības un Līdzdalības uzņēmumos piemērs\"\n        title=\"\"\n        src=\"/static/d427ed385906ee7f688c53ef68fc9ca5/fcda8/article3_lv_image8.png\"\n        srcset=\"/static/d427ed385906ee7f688c53ef68fc9ca5/12f09/article3_lv_image8.png 148w,\n/static/d427ed385906ee7f688c53ef68fc9ca5/e4a3f/article3_lv_image8.png 295w,\n/static/d427ed385906ee7f688c53ef68fc9ca5/fcda8/article3_lv_image8.png 590w,\n/static/d427ed385906ee7f688c53ef68fc9ca5/efc66/article3_lv_image8.png 885w,\n/static/d427ed385906ee7f688c53ef68fc9ca5/e548f/article3_lv_image8.png 975w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    }), "\n", _jsx(_components.h2, {
      children: "Iebildumi par kredītvēstures saturu"
    }), "\n", _jsx(_components.p, {
      children: "Šajā sadaļā ir apkopota informācija par Personas iebildumiem par Kredītvēstures saturu – gan aktīvo iebildumu skaits, gan noraidīto un arī Tiesā reģistrēto iebildumu skaits."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.div, {
        dangerouslySetInnerHTML: {
          __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 590px; \"\n    >\n      <a\n    class=\"gatsby-resp-image-link\"\n    href=\"/static/af17c7c475f623f2a031e087f95f283c/e548f/article3_lv_image9.png\"\n    style=\"display: block\"\n    target=\"_blank\"\n    rel=\"noopener\"\n  >\n    <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 15.54054054054054%; position: relative; bottom: 0; left: 0; background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAiklEQVQI122OSwrCQBAFc/8z6A3ceQ4hG3HhAWSU6PySnm9KMkSJ4INaNI+u7g4/gR4p+kW8K8JK9o5t5nlu/MunW+j624X96cBTKSZjKCLUEEgieO9RSqG1ptbaFkIITOPIMAyNZd5Ku/5xZXc+YozFOkddr6acm9BZS0rpKxQRcs7EGBullJ8P3z6r6GvbCsvhAAAAAElFTkSuQmCC'); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"Iebildumi par kredītvēstures saturu piemērs\"\n        title=\"\"\n        src=\"/static/af17c7c475f623f2a031e087f95f283c/fcda8/article3_lv_image9.png\"\n        srcset=\"/static/af17c7c475f623f2a031e087f95f283c/12f09/article3_lv_image9.png 148w,\n/static/af17c7c475f623f2a031e087f95f283c/e4a3f/article3_lv_image9.png 295w,\n/static/af17c7c475f623f2a031e087f95f283c/fcda8/article3_lv_image9.png 590w,\n/static/af17c7c475f623f2a031e087f95f283c/efc66/article3_lv_image9.png 885w,\n/static/af17c7c475f623f2a031e087f95f283c/e548f/article3_lv_image9.png 975w\"\n        sizes=\"(max-width: 590px) 100vw, 590px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n  </a>\n    </span>"
        }
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
